<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding" colspan="3">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" colspan="12">
            Purchase Order of Supplier Manpower <br />
            当地岗位服务外包人员订单管理
          </td>
        </tr>

        <tr>
          <td class="text-center">SN<br/>序号</td>
          <td class="text-center">Application Date<br/>申请日期</td>
          <td class="text-center">First Party<br/>甲方</td>
          <td class="text-center">Project Name<br/>项目名称</td>
          <td class="text-center">PO No<br/>订单号</td>
          <td class="text-center">Second Party Abbreviation Name<br/>乙方</td>
          <td class="text-center">Category & Discipline <br/>岗位系统/专业</td>
          <td class="text-center">Job Profession<br/>工作岗位</td>
          <td class="text-center">Qualif<br/>资质</td>
          <td class="text-center">Qty<br />数量</td>
          <td class="text-center">Rate (SR/Hr)<br/>费率</td>
          <td class="text-center">FAT Beared By</td>
          <td class="text-center">Previous PO. No. (If combine old PO)</td>
          <td class="text-center">End of Usage Duration<br/>使用结束日期</td>
          <td class="text-center">Remarks<br/>备注</td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">
            {{ index + 1 }}
          </td>
          <td class="text-center">{{ item.EmployeeID }}</td>
          <td class="text-center">{{ item.NameinEnglish }}</td>
          <td class="text-center">
            {{ item.Nationality | nationalityFormater(nationalityList) }}
          </td>
          <td class="text-center">
            {{ item.JobProfession | positionFormater(positionList) }}
          </td>
          <td class="text-center">
            {{ item.DatetoFirstEntrySaudi | tableDateFrom }}
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="item.DepartureDatefromKSA"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </td>
          <td class="text-center">
            <el-select v-model="item.AirTicketRoute" placeholder="请选择">
              <el-option
                v-for="item in ticketGroupList"
                :key="item.ID"
                :label="item.NameCn"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-input
              v-model="item.PassportCode"
              @input="changePassportCode(index)"
              @blur="changePassportCode(index)"
              placeholder="请输入护照号"
              :disabled="
                CostAllocationDetails[index - 1] &&
                !CostAllocationDetails[index - 1].PassportCode
              "
            />
          </td>
          <td class="text-center">
            <el-input />
          </td>
          <td class="text-center">
            <el-input />
          </td>
          <td class="text-center">
            <el-input />
          </td>
          <td class="text-center">
            <el-input />
          </td>
          <td class="text-center">
            <el-input />
          </td>
          <td class="text-center">
            <el-input />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  allPosition,
  getProjectsChildren,
} from "@/api/user";
import {
  addDemobilizationApplications,
  updateDemobilizationApplication,
  getUsersCode,
  getGeneralBasic,
  getTicketGroupAll,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    companyFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CompanyCHS : "";
    },
    departmentFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.DepartmentCHS : "";
    },
    nationalityFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.CountryNameCHS : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    SponsorFormater(id) {
      const data = [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ];
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : null;
    },
  },
  data() {
    return {
      projectList: [],
      companyList: [],
      departmentList: [],
      nationalityList: [],
      positionList: [],
      ticketGroupList: [],
      subProjectList: [],
      ID: null,
      KeyID: null,
      CostAllocationDetails: [],
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        EmployeeID: null,
        NameinEnglish: null,
        Nationality: null,
        JobProfession: null,
        DatetoFirstEntrySaudi: null,
        DepartureDatefromKSA: null,
        AirTicketRoute: null,
        PassportCode: null,
        ForeignRelationStatus: null,
      },
      options: [
        {
          value: 1,
          label: "注销Final Exit/Cancel IQAMA/Sponsor Transfer",
        },
        {
          value: 2,
          label: "保留 Holding Re-entry Visa",
        },
      ],
    };
  },
  methods: {
    getDepartmentList(data1, data2, data3, data4, data5) {
      this.projectList = data1;
      this.companyList = data2;
      this.departmentList = data3;
      this.nationalityList = data4;
      this.positionList = data5;
      this.TableDate = getNewDate();
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        ProjectID: this.ProjectID,
        SubProjectID: this.SubProjectID,
        CompanyID: this.CompanyID,
        DepartmentID: this.DepartmentID,
        Sponsor: this.Sponsor,
        Reason: this.Reason,
        HROfficerMemo: this.HROfficerMemo,
        ForeignAffairOfficerMemo: this.ForeignAffairOfficerMemo,
        TicketOfficerMemo: this.TicketOfficerMemo,
        EditState: EditState,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.PassportCode && item.EmployeeID) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });
      let isTrue = true;
      data.Details.forEach((item) => {
        if (!item.DepartureDatefromKSA) {
          this.$message.warning("沙特起飞日期不能为空");
          isTrue = false;
        }
        if (!item.AirTicketRoute) {
          this.$message.warning("请求选择飞行路线");
          isTrue = false;
        }
        if (!item.ForeignRelationStatus) {
          this.$message.warning("请求选择外事关系");
          isTrue = false;
        }
      });
      if (!isTrue) {
        return;
      }

      if (!this.ID) {
        addDemobilizationApplications(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("updata");
            this.$emit("close");
          }
        });
      } else {
        updateDemobilizationApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("updata");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(data1, data2, data3, data4, data5, params) {
      this.projectList = data1;
      this.companyList = data2;
      this.departmentList = data3;
      this.nationalityList = data4;
      this.positionList = data5;
      console.log("params", params);

      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.ProjectID = params.ProjectID;
      this.SubProjectID = params.SubProjectID;
      this.CompanyID = params.CompanyID;
      this.DepartmentID = params.DepartmentID;
      this.Sponsor = params.Sponsor;
      this.Reason = params.ReasonofDemobilization;
      this.ApplicationNumber = params.ApplicationNumber;
      this.TableDate = params.CreateDate;
      this.HROfficerMemo = params.HROfficerMemo;
      this.ForeignAffairOfficerMemo = params.ForeignAffairOfficerMemo;
      this.TicketOfficerMemo = params.TicketOfficerMemo;
      this.getProjectsChildren();
      this.CostAllocationDetails.forEach((_, index, Array) => {
        if (params.Details[index]) {
          //员工编号
          params.Details[index].EmployeeID = params.Details[index].EmployeeID
            ? params.Details[index].EmployeeID
            : params.Details[index].EmployeeCode;
          //员工英文名
          params.Details[index].NameinEnglish =
            params.Details[index].EmployeeNameEN;
          this.CostAllocationDetails[index] = JSON.parse(
            JSON.stringify(params.Details[index])
          );
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();
      this.WorkingUnitAdminDeptRemark = params.WorkingUnitAdminDeptRemark;
      this.WorkingUnitManagerRemark = params.WorkingUnitManagerRemark;
      this.ProjectAdminDeptRemark = params.ProjectAdminDeptRemark;
      this.ProjectControlDeptRemark = params.ProjectControlDeptRemark;
      this.ProjectProcurementDeptRemark = params.ProjectProcurementDeptRemark;
      this.ProjectEngineeringDeptRemark = params.ProjectEngineeringDeptRemark;
      this.ProjectHSSEDeptRemark = params.ProjectHSSEDeptRemark;
      this.ProjectQADeptRemark = params.ProjectQADeptRemark;
      this.ProjectDesignDeptRemark = params.ProjectDesignDeptRemark;
      this.ProjectManagerRemark = params.ProjectManagerRemark;
      this.HRDeptRemark = params.HRDeptRemark;
      this.SNEMECAuthorizedRemark = params.SNEMECAuthorizedRemark;
      this.HROfficerRemark = params.HROfficerRemark;
      this.ForeignAffairOfficerRemark = params.ForeignAffairOfficerRemark;
      this.TicketOfficerRemark = params.TicketOfficerRemark;
    },
    clearData() {
      this.ID = null;
      this.KeyID = null;
      this.ProjectID = null;
      this.SubProjectID = null;
      this.CompanyID = null;
      this.DepartmentID = null;
      this.Sponsor = null;
      this.Reason = null;
      this.HROfficerMemo = null;
      this.ForeignAffairOfficerMemo = null;
      this.TicketOfficerMemo = null;
      this.CostAllocationDetails = [];
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
      this.WorkingUnitAdminDeptRemark = null;
      this.WorkingUnitManagerRemark = null;
      this.ProjectAdminDeptRemark = null;
      this.ProjectControlDeptRemark = null;
      this.ProjectProcurementDeptRemark = null;
      this.ProjectEngineeringDeptRemark = null;
      this.ProjectHSSEDeptRemark = null;
      this.ProjectQADeptRemark = null;
      this.ProjectDesignDeptRemark = null;
      this.ProjectManagerRemark = null;
      this.HRDeptRemark = null;
      this.SNEMECAuthorizedRemark = null;
      this.HROfficerRemark = null;
      this.ForeignAffairOfficerRemark = null;
      this.TicketOfficerRemark = null;
    },
    getCostCenters(index, type) {
      const item = JSON.parse(
        JSON.stringify(this.CostAllocationDetails[index])
      );

      const data = {
        projectId: item.ProjectID,
        companyId: item.CompanyID,
        deptId: item.DepartmentID,
      };
      if (type !== 2) {
        item.SubProjectID = null;
        if (item.ProjectID) {
          this.getProjectsChildren(index, item.ProjectID);
        } else {
          this.SubProjeList = [];
        }
      }
      allCostCenters(data).then((res) => {
        if (res.status === 200) {
          item.CostCenterList = res.response;
          if (res.response.length === 0) {
            this.$message.warning("该选项下不存在成本中心，请重新选择");
          }
          if (res.response.length === 1) {
            item.CostCenterID = res.response[0].Id;
            item.CostCenterCode = res.response[0].Code;
          }
        }
      });
      this.CostAllocationDetails.splice(index, 1, item);
    },
    //生成五项数据
    getCostCenterCode(index) {
      const item = JSON.parse(
        JSON.stringify(this.CostAllocationDetails[index])
      );
      item.CostCenterList.forEach((cItem) => {
        if (cItem.Id === item.CostCenterID) {
          item.CostCenterCode = cItem.Code;
        }
      });
      this.CostAllocationDetails.splice(index, 1, item);
    },
    //通过录入护照号拿个人信息
    async changePassportCode(index) {
      const value = this.CostAllocationDetails[index].PassportCode;
      if (!value) {
        return;
      }
      let userInfo = null;
      await getUsersCode({ code: value, codeType: 1 }).then((res) => {
        if (res.status === 200 && res.response) {
          userInfo = res.response;
        }
      });
      if (!userInfo) {
        this.CostAllocationDetails[index].EmployeeID = null;
        this.CostAllocationDetails[index].NameinEnglish = null;
        this.CostAllocationDetails[index].Nationality = null;
        this.CostAllocationDetails[index].JobProfession = null;
        this.CostAllocationDetails[index].DatetoFirstEntrySaudi = null;
        return;
      }
      //判断有几条数据了
      let haveNumber = 0;
      this.CostAllocationDetails.forEach((item) => {
        if (item.PassportCode) {
          haveNumber++;
        }
      });

      if (haveNumber > 1) {
        console.log("userInfo", userInfo);

        if (userInfo.ProjectID !== this.ProjectID) {
          this.$message.warning("所属项目名称不一致");
          this.CostAllocationDetails[index].PassportCode = null;
          return;
        }
        if (
          userInfo.SubProjectID !== this.SubProjectID &&
          userInfo.SubProjectID !== null
        ) {
          this.$message.warning("所属分项目名称不一致");
          this.CostAllocationDetails[index].PassportCode = null;
          return;
        }
        if (userInfo.CompanyID !== this.CompanyID) {
          this.$message.warning("所属用工单位不一致");
          this.CostAllocationDetails[index].PassportCode = null;
          return;
        }
        if (userInfo.CompanyID !== this.CompanyID) {
          this.$message.warning("所属用工单位不一致");
          this.CostAllocationDetails[index].PassportCode = null;
          return;
        }
        if (userInfo.DepartmentID !== this.DepartmentID) {
          this.$message.warning("所属部门信息不一致");
          this.CostAllocationDetails[index].PassportCode = null;
          return;
        }
        if (userInfo.SPONSOR !== this.SPONSOR) {
          this.$message.warning("所属SPONSOR不一致");
          this.CostAllocationDetails[index].PassportCode = null;
          return;
        }
        this.CostAllocationDetails[index].EmployeeID = userInfo.UserCode;
        this.CostAllocationDetails[index].NameinEnglish = userInfo.EnglishName;
        this.CostAllocationDetails[index].Nationality = userInfo.CountryID;
        this.CostAllocationDetails[index].JobProfession = userInfo.PositionID;
        this.CostAllocationDetails[index].DatetoFirstEntrySaudi =
          userInfo.DatetoFirstEntrySaudi;
      } else {
        console.log("userInfo", userInfo);
        this.ProjectID = userInfo.ProjectID;
        this.SubProjectID = userInfo.SubProjectID;
        this.CompanyID = userInfo.CompanyID;
        this.DepartmentID = userInfo.DepartmentID;
        this.Sponsor = userInfo.Sponsor;
        this.CostAllocationDetails[index].EmployeeID = userInfo.UserCode;
        this.CostAllocationDetails[index].NameinEnglish = userInfo.EnglishName;
        this.CostAllocationDetails[index].Nationality = userInfo.CountryID;
        this.CostAllocationDetails[index].JobProfession = userInfo.PositionID;
        this.CostAllocationDetails[index].DatetoFirstEntrySaudi =
          userInfo.DatetoFirstEntrySaudi;
        this.getProjectsChildren();
      }
    },
    //获取列表子项目
    getProjectsChildren() {
      getProjectsChildren({ id: this.ProjectID }).then((res) => {
        if (res.status === 200) {
          this.subProjectList = res.response;
        }
      });
    },
  },
  created() {
    this.TableDate = getNewDate();
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    //国籍
    getGeneralBasic().then((res) => {
      if (res.status === 200) {
        this.nationalityList = res.response.Countries;
        console.log("国籍", this.nationalityList);
      }
    });
    //岗位
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    //全部飞行路线
    getTicketGroupAll().then((res) => {
      if (res.status === 200) {
        this.ticketGroupList = res.response;
        console.log("全部飞行路线", this.ticketGroupList);
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        max-width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 10px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}
</style>